export default function calculateDeliveryFees(points = [] ,callback = () => {}) {
	if (points.length < 2) return

	let origins = points.map(point => new window.google.maps.LatLng(point.lat, point.lng))
	let destinations = points.map(point => new window.google.maps.LatLng(point.lat, point.lng))
	let service = new window.google.maps.DistanceMatrixService()


	console.log(destinations)

	service.getDistanceMatrix({
		origins: origins,
		destinations: destinations,
		travelMode: 'DRIVING'
	}, (response) => {
		let sum = 900
		for (let a = 0; a < points.length; a++) {
			try {
				let distance =  response.rows[a].elements[a + 1].distance.value
				if(distance > 11000 && distance < 16000)
					sum = sum + 50
				else if(distance > 16000 )	
					sum = sum + 75
			} catch (err) {
				console.log('out of bounds')
			}
		}

		callback(sum)
	})

}
