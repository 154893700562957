import React, { useState, useEffect } from 'react'
import { EmptyMap, CustomMarkers } from '../components/spark-express/map/google-map'

import GooglePlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-google-places-autocomplete"


import getDistance from '../services/google directions'

const Calculate = () => {
	const [markers, setMarkers] = useState([])
	const [computing, toggleComputing] = useState(false)
	const [cost, setCost] = useState(0)

	useEffect(() => {
		let mounted = true

		if (mounted) {
			getDistance(markers, (deliveryFee) => {
				console.log('delivery fee will be ')
				setCost(deliveryFee)
			})
		}

		return () => {
			mounted = false
		}
	}, [markers])

	return <> <EmptyMap > <CustomMarkers markers={markers} /></EmptyMap>

		<div style = {{
			display: 'flex', 
			
		}}>
			Delivery Fee: {cost}
		</div>

		{computing ? <GooglePlacesAutocomplete
			apiKey='AIzaSyCwjH9_I1j2FH89huz-Ld5YVHxNM0mVhn0'
			selectProps={{
				onChange: (params) => {
					geocodeByAddress(params.label).then(results => getLatLng(results[0]))
						.then(({ lat, lng }) => {
							setMarkers((prevMarkers) => [...prevMarkers, { lat, lng, label: params.label }])
						})
				}
			}}
		/> : <button style={{
			marginTop: '1em'
		}} onClick={() => toggleComputing(true)}>compute</button>}

		{markers.map((marker, key) => {
			return <>
				<div key={marker.label} style={{
					display: 'flex',
					marginTop: '0.5em'
				}}>
					<div style={{ width: '80%' }}>{marker.label}</div>
					<div><button onClick={() => {
						console.log(key)
						setMarkers([...markers.slice(0, key), ...markers.slice(key + 1)])
					}}>delete</button></div>
				</div>
			</>
		})}



	</>
}


export default Calculate